 @font-face {
    font-family: 'Montserrat-ExtraBold';
    font-display: fallback;
    src: url('/assets/fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
    url('/assets/fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Montserrat-Bold';
    font-display: fallback;
    src: url('/assets/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('/assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Montserrat-SemiBold';
    font-display: fallback;
    src: url('/assets/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Montserrat';
    font-display: fallback;
    src: url('/assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('/assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Montserrat-Thin';
    font-display: fallback;
    src: url('/assets/fonts/Montserrat/Montserrat-Thin.woff2') format('woff2'),
    url('/assets/fonts/Montserrat/Montserrat-Thin.woff') format('woff');
  }

  @font-face {
    font-family: 'Montserrat-Light';
    font-display: fallback;
    src: url('/assets/fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
    url('/assets/fonts/Montserrat/Montserrat-Light.woff') format('woff');
  }
