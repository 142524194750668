/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import './styles/default/variables.scss';

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

.login-div{
    height: 100%;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}

.wordpress{
  font-family: "Montserrat";
  a{
    color: $primaryColor !important;
    font-style: italic;
    text-decoration: none;
  }
  a:hover{
    color: $primaryColor !important;
    text-decoration: underline;
  }

  .wp-block-heading {
    font-size: 1.6rem !important;
    color: black !important;
    font-weight: bold
  }

  strong{
    color: black !important;
    font-weight: bold;
  }
  .wp-block-table{
    table{
      margin: auto;
    }
  }

  .wp-block-image{
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    img{
      border-radius: 5px;
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center;
    }
    a{
      img{
        border-radius: 5px;
      }
    }
  }
}
